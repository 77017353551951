// assets

import {  mdiSailBoat,mdiPlus } from '@mdi/js';




// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const tournus = {
    id: 'tournus',
    title: 'Imprezy',
    type: 'group',
    children: [
        {
            id: 'tournusList',
            title: 'Lista',
            type: 'item',
            url: '/turnus/list',
            icon: mdiSailBoat,
            target: false
        },
        {
            id: 'tournusAdd',
            title: 'Dodaj',
            type: 'item',
            url: '/turnus/add',
            icon: mdiPlus,
            target: false
        },

    ]
};

export default tournus;
