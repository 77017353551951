// assets
import { mdiMultimedia } from '@mdi/js';
// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const media = {
    id: 'media',
    title: 'Media',
    type: 'group',
    children: [
        {
            id: 'mediaItem',
            title: 'Media',
            type: 'item',
            url: '/media',
            icon: mdiMultimedia,
            target: false
        },

    ]
};

export default media;
