// @ts-nocheck
import PropTypes from 'prop-types';
import { useMemo } from 'react';

// material-ui
import { CssBaseline, StyledEngineProvider, ThemeOptions } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// project import
import React from 'react';
import Palette from './palette';
import Typography from './typography';
import CustomShadows from './shadows';
import componentsOverride from './overrides';

import { plPL } from '@mui/material/locale';
// ==============================|| DEFAULT THEME - MAIN  ||============================== //
import blue from '@mui/material/colors/blue';
type ThemeCustomizationProps = {
    children: React.ReactNode
}
declare module '@mui/material/styles' {
    interface Theme {
        customShadows: { [key: string]: any },
        navbar?: { [key: string]: any }
        navbarText?: { [key: string]: any }
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        //customShadows: { [key: string]: any },
        custom?: { [key: string]: any }
        navbar?: { [key: string]: any }
    }
    interface PaletteOptions {
        navbarText:  {[key: string]: any }

    }
    interface Palette {
        navbar:  {[key: string]: any }
        navbarText:  {[key: string]: any }
      }
}

export default function ThemeCustomization(props: ThemeCustomizationProps) {
    const theme = Palette(['light', 'default']);


    const themeTypography = Typography("'Plus Jakarta Sans', sans-serif");
    const themeCustomShadows = useMemo(() => CustomShadows(theme), [theme]);


    //const themeTypography=Typography('Open sans')



    const themeOptions = {


        palette: {
            primary: {
                // light: will be calculated from palette.primary.main,
                // main: '#193d63',
                main: '#5a3876'
                // dark: will be calculated from palette.primary.main,
                // contrastText: will be calculated to contrast with palette.primary.main
            },
            //navbar: "#193d63",
            navbar:"#5a3876",
            navbarText: {
                item: "#c5c5c5",
                subItem: "#c5c5c5",
            },
            secondary: {
                light: '#0066ff',
                main: '#0044ff',
                // dark: will be calculated from palette.secondary.main,
                contrastText: '#ffcc00',
            },


        },
        customShadows: themeCustomShadows,
        typography: themeTypography
    }

    const themes = createTheme(themeOptions, plPL);
    // themes.typography.h2 = {

    //     fontSize: '20px'

    // }
    themes.components = componentsOverride(themes);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={themes}>
                <CssBaseline />
                {props.children}
            </ThemeProvider>
        </StyledEngineProvider>
    );
}


