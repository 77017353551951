import { useRef, useState, useEffect} from 'react';

import minutesPassedFromDate from '../../../../utils/minutesAndDays';
// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Avatar,
    Box,
    ClickAwayListener,
    Divider,
    IconButton,
    List,
    ListItemButton,
    ListItemAvatar,
    ListItemText,
    ListItemSecondaryAction,
    Paper,
    Popper,
    Typography,
    useMediaQuery,

} from '@mui/material';

// project import
import MainCard from '../../../../components/MainCard';
import Transitions from '../../../../components/@extended/Transitions';
import { Link, useLocation } from 'react-router-dom';
// assets
import { CloseOutlined } from '@ant-design/icons';
import { useUnseenBookingQuery } from '../../../../services/bookingApi';
import { mdiSailBoat } from '@mdi/js';
import { Icon } from '@mdi/react';
import BellNotification from './BellNotification';
// sx styles
const avatarSX = {
    width: 36,
    height: 36,
    fontSize: '1rem'
};

const actionSX = {
    mt: '6px',
    ml: 1,
    top: 'auto',
    right: 'auto',
    alignSelf: 'flex-start',
    transform: 'none'
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

const Notification = () => {
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.down('md'));
    const { data: bookings, refetch } = useUnseenBookingQuery(undefined)
    const location = useLocation();
    const anchorRef = useRef<any>(null);
    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event:MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };


    const iconBackColorOpen = 'grey.300';
    const iconBackColor = 'grey.100';

    // hide notyfication when location cahnge
    useEffect(() => {
        setOpen(false)
    }   , [location])

    //refech bookings when open notification in useeffect
    useEffect(() => {
        if (open) {
            refetch()
        }
    }, [open, ])


    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            <IconButton
                disableRipple
                color="secondary"
                sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : iconBackColor }}
                aria-label="open profile"
                ref={anchorRef}
                aria-controls={open ? 'profile-grow' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
<BellNotification />
            </IconButton>
            <Popper
                placement={matchesXs ? 'bottom' : 'bottom-end'}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                popperOptions={{
                    modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [matchesXs ? -5 : 0, 9]
                            }
                        }
                    ]
                }}
            >
                {({ TransitionProps }) => (
                    <Transitions type="fade" in={open} {...TransitionProps}>
                        <Paper
                            sx={{
                                boxShadow: theme.customShadows.z1,
                                width: '100%',
                                minWidth: 285,
                                maxWidth: 420,
                                [theme.breakpoints.down('md')]: {
                                    maxWidth: 285
                                }
                            }}
                        >
                            <ClickAwayListener onClickAway={handleClose}>
                                <MainCard
                                    title="Ostatnie rezerwacje"
                                    elevation={0}
                                    border={false}
                                    content={false}
                                    secondary={
                                        <IconButton size="small" onClick={handleToggle}>
                                            <CloseOutlined />
                                        </IconButton>
                                    }
                                >
                                    <List
                                        component="nav"
                                        sx={{
                                            p: 0,
                                            '& .MuiListItemButton-root': {
                                                py: 0.5,
                                                '& .MuiAvatar-root': avatarSX,
                                                '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                                            }
                                        }}
                                    >
                                        {bookings?.length === 0 && <Typography variant="h6" align="center" sx={{ mt: 2, mb: 2 }}>Brak nowych rezerwacji</Typography>}
                                        {bookings?.map((item,index) => {




const ago = minutesPassedFromDate(item.bookingDate['$date']);




                                            return (
                                                <>
                                                    <Link to={`/booking/${item._id.$oid}`} style={{'all':'unset'}}>    <ListItemButton key={index} >
                                            <ListItemAvatar>
                                                <Avatar  variant="square"
                                                    sx={{
                                                        color: 'primary.main',
                                                        bgcolor: '#eee'
                                                    }}
                                                >
                                                    <Icon path={mdiSailBoat} size={0.9} />
                                                </Avatar>
                                            </ListItemAvatar>
                                            <ListItemText
                                                primary={
                                                    <Typography variant="h6">

                                                        <Typography component="span" variant="subtitle1">
                                                           {item.zglaszani['u-imie']}  {item.zglaszani['u-nazwisko']}
                                                                    </Typography>
                                                                    <Typography component="span" variant="subtitle2">
                                                                   &nbsp; {item.bookingNumber}
                                                                        </Typography>
                                                    </Typography>
                                                }
                                                secondary={ago}
                                            />
                                            <ListItemSecondaryAction>
                                                <Typography variant="caption" noWrap>
{item.imprezaname} / {item.tourcode}
                                                </Typography>
                                            </ListItemSecondaryAction>
                                        </ListItemButton></Link>
                                        <Divider /></>)

                                        })}

                                    </List>
                                </MainCard>
                            </ClickAwayListener>
                        </Paper>
                    </Transitions>
                )}
            </Popper>
        </Box>
    );
};

export default Notification;
