// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState = {
    isToastOpen: false,
    message: '',
    type:''
};

// ==============================|| SLICE - MENU ||============================== //

const toast = createSlice({
    name: 'toast',
    initialState,
    reducers: {
        openToast(state,action,type=undefined) {
            state.isToastOpen = true;
            state.message = action.payload.message;
            state.type = action.payload.type;
        },

        closeToast(state) {
            state.isToastOpen = false;

        },


    }
});
export default toast
export const { openToast,  closeToast } = toast.actions;
