import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import React, { FC } from 'react';

// ==============================|| NAVIGATION - SCROLL TO TOP ||============================== //

type ScrollTopProps = {
    children:any
}
const ScrollTop = ({ children }: ScrollTopProps) => {
    const location = useLocation();
    const { pathname } = location;

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
    }, [pathname]);

    return children;
};



export default ScrollTop;
