// project import

import Navigation from './Navigation';
import SimpleBar from '../../../../components/third-party/SimpleBar';

// ==============================|| DRAWER CONTENT ||============================== //

const DrawerContent = () => (

    <SimpleBar
        sx={{
            '& .simplebar-content': {
                display: 'flex',
                flexDirection: 'column',
                color: 'white',
                height:'100%'
            }
        }}
    >
        <Navigation />

        </SimpleBar>

);

export default DrawerContent;
