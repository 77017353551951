import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Chip, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';

// project import
import { activeItem } from '../../../../../store/menu';
import Icon from '../../../../../Icon';

// ==============================|| NAVIGATION - LIST ITEM ||============================== //


type NavItemProps = {
    item: any,
    level: number
}

type componentType =
    { component: any, href?: any, target?: any, to?: any }


const NavItem = (props: NavItemProps) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const menu = useSelector((state: any) => state.menu);
    const { drawerOpen, openItem } = menu;

    let itemTarget = '_self';
    if (props.item.target) {
        itemTarget = '_blank';
    }

    let listItemProps: componentType = { component: forwardRef((propsx: any, ref: any) => <Link ref={ref} {...propsx} to={props.item.url} target={itemTarget} />) };

    if (props.item?.external) {
        listItemProps = { component: 'a', href: props.item.url, target: itemTarget };
    }

    const itemHandler = (id: number) => {
        dispatch(activeItem({ openItem: [id] }));
    };


    const itemIcon = props.item.icon ? props.item.icon : false;

    const isSelected = openItem.findIndex((id: number) => id === props.item.id) > -1;

    // active menu item on page load
    useEffect(() => {
        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === props.item.id);
        if (currentIndex > -1) {
            dispatch(activeItem({ openItem: [props.item.id] }));
        }
        // eslint-disable-next-line
    }, []);

    const textColor = theme.palette.navbarText.subItem
    const iconSelectedColor = 'navbarText.subItem';

    return (
       <ListItemButton
            {...listItemProps}
            disabled={props.item.disabled}
            onClick={() => itemHandler(props.item.id)}
            selected={isSelected}
            sx={{
                zIndex: 1201,
                pl: drawerOpen ? `${props.level * 28}px` : 1.5,
                py: !drawerOpen && props.level === 1 ? 1.25 : 1,
                ...(drawerOpen && {
                    '&:hover': {
                        bgcolor: 'primary.lighter'
                    },
                    '&.Mui-selected': {
                        bgcolor: 'primary.lighter',
                        borderRight: `2px solid ${theme.palette.primary.main}`,
                        color: theme.palette.navbarText.subItem,
                        '&:hover': {
                            color: iconSelectedColor,
                            bgcolor: 'primary.lighter'
                        }
                    }
                }),
                ...(!drawerOpen && {
                    '&:hover': {
                        bgcolor: 'transparent'
                    },
                    '&.Mui-selected': {
                        '&:hover': {
                            bgcolor: 'transparent'
                        },
                        bgcolor: 'transparent'
                    }
                })
            }}
        >
            {itemIcon && (
                <ListItemIcon

                    sx={{
                        minWidth: 28,
                        color: isSelected ? iconSelectedColor : textColor,
                        ...(!drawerOpen && {
                            borderRadius: 1.5,
                            width: 36,
                            height: 36,
                            alignItems: 'center',
                            justifyContent: 'center',
                            '&:hover': {
                                bgcolor: 'secondary.lighter'
                            }
                        }),
                        ...(!drawerOpen &&
                            isSelected && {
                            bgcolor: 'primary.lighter',
                            '&:hover': {
                                bgcolor: 'primary.lighter'
                            }
                        })
                    }}
                >
                    <Icon size={1} path={itemIcon} fillColor={theme.palette.navbarText.subItem} />
                </ListItemIcon>
            )}
            {(drawerOpen || (!drawerOpen && props.level !== 1)) && (
                <ListItemText
                    primary={
                        <Typography variant="subtitle2" sx={{ color: isSelected ? iconSelectedColor : textColor }}>
                         {props.item.title}
                        </Typography>
                    }
                />
            )}
            {(drawerOpen || (!drawerOpen && props.level !== 1)) && props.item.chip && (
               <Chip
                    color={props.item.chip.color}
                    variant={props.item.chip.variant}
                    size={props.item.chip.size}
                    label={props.item.chip.label}
                    avatar={props.item.chip.avatar && <Avatar variant="square">{props.item.chip.avatar}</Avatar>}
                />

            )}
        </ListItemButton>
    );
};


export default NavItem;
