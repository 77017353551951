import React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";

import { closeToast } from '../../../store/toast';

type ToastProps = {
    type: "success" | "error",
    closeAfter?: number,
    message?: string
}

const ToastComponent = (props:ToastProps) => {
    // const dispatch = useDispatch();
    const { isToastOpen, message, type} = useSelector((state:any) => state.toast);


    const dispatch = useDispatch();


    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        dispatch(closeToast())
    };
    const action = (
        <React.Fragment>

            <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => { dispatch(closeToast()) }}
            >
                <CloseIcon fontSize="small" />
            </IconButton>
        </React.Fragment>
    );
    return (
        <Snackbar
        ContentProps={{
            sx: {
              background: type=='error' ? "red" :"green"
            }
          }}
            open={isToastOpen}
            autoHideDuration={2000}
            onClose={handleClose}
            message={message || 'wystąpił problem'}
            action={action}

        />
    );
}
ToastComponent.defaultProps = {
    closeAfter: 6000,
    type: "success"
}
export default ToastComponent;