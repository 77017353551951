// assets

import { mdiTextBoxOutline } from '@mdi/js';




// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const pages = {
    id: 'pages',
    title: 'Strony',
    type: 'group',
    children: [
        {
            id: 'pageList',
            title: 'Lista stron',
            type: 'item',
            url: '/page/list',
            icon: mdiTextBoxOutline,
            target: false
        },

    ]
};

export default pages;
