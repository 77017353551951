import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import React from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import { Card, CardContent, CardHeader, Divider, Typography } from '@mui/material';

// project import


// header style
const headerSX = {
    p: 2.5,
    '& .MuiCardHeader-action': { m: '0px auto', alignSelf: 'center' }
};


// ==============================|| CUSTOM - MAIN CARD ||============================== //

type MainCardProps = {
    border?: boolean,
    boxShadow?: boolean,
    contentSX?: any,
    darkTitle?: boolean,
    divider?: boolean,
    elevation?: number,
    secondary?: React.ReactNode,
    shadow?: string,
    sx?: any,
    title?: string,
    codeHighlight?: boolean,
    content?: boolean,
    children: React.ReactNode,
    headerSX?: any
}

const MainCard = forwardRef(
    (
        props: MainCardProps,
        ref:any
    ) => {
        const theme = useTheme();
        const boxShadow = theme.palette.mode === 'dark' ? props.boxShadow || true : props.boxShadow;

        return (
            <Card
                elevation={props.elevation || 0}
                ref={ref}
                sx={{
                    ...props.sx,
                    border: props.border ? '1px solid' : 'none',
                    borderRadius: 2,
                    borderColor: theme.palette.mode === 'dark' ? theme.palette.divider : '#000',
                    boxShadow: boxShadow && (!props.border || theme.palette.mode === 'dark') ? props.shadow || theme.customShadows.z1 : 'inherit',
                    ':hover': {
                        boxShadow: props.boxShadow ? props.shadow || theme.customShadows.z1 : 'inherit'
                    },
                    '& pre': {
                        m: 0,
                        p: '16px !important',
                        fontFamily: theme.typography.fontFamily,
                        fontSize: '0.75rem'
                    }
                }}
            >
                {/* card header and action */}
                {!props.darkTitle && props.title && (
                    <CardHeader sx={props.headerSX} titleTypographyProps={{ variant: 'subtitle1' }} title={props.title} action={props.secondary} />
                )}
                {props.darkTitle && props.title && (
                    <CardHeader sx={props.headerSX} title={<Typography variant="h3">{props.title}</Typography>} action={props.secondary} />
                )}

                {/* content & header divider */}
                {props.title && props.divider && <Divider />}

                {/* card content */}
                {props.content && <CardContent sx={props?.contentSX}>{props.children}</CardContent>}
                {!props.content && props.children}

                {/* card footer - clipboard & highlighter  */}
                {props.codeHighlight && (
                    <>
                        <Divider sx={{ borderStyle: 'dashed' }} />

                        {props.children}

                    </>
                )}
            </Card>
        );
    }
);


export default MainCard;
