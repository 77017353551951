
import { Link } from 'react-router-dom';

// material-ui
import { Box, ButtonBase } from '@mui/material';

// project import
import Logo from './Logo';
import config from '../../config';
import { SxProps } from '@mui/material';

// ==============================|| MAIN LOGO ||============================== //

type LogoSection = {
    sx?: SxProps;
    to?: string;
}

const LogoSection = ({ sx }:LogoSection) => (
        <Box sx={{ display: 'flex', alignItems: 'center', ...sx }}>
        <img src="/m.png" />
        </Box>

);



export default LogoSection;
