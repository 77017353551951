import { isFulfilled, isRejected } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit'
import { openToast } from '../store/toast';
import { openredirect} from '../store/redirect';
import { Cookies } from 'react-cookie';
import { store } from '../store';



export const rtkQueryErrorLogger: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {

   //
    // if (!isRejected(action)) {
    //   api.dispatch(openToast({ message: 'zapisano \n'+action?.payload?.data?.title || "" }))
    // }


    if (isFulfilled(action)) {

      if (action.meta.arg.type === "mutation"  && action?.payload?.status!=='error') {
        api.dispatch(openToast({ message: 'operacja zakoczończona sukcesem ' }))
      }
      if (action.meta.arg.type === "mutation"  && action?.payload?.status==='error') {
        api.dispatch(openToast({ message: action?.payload?.message,type:'error' }))
      }
    }





    if (isRejected(action) && action?.error?.name!=='ConditionError') {

      if (action.payload?.data?.title === '401 Unauthorized')
      {
        api.dispatch(openredirect({isAuth:false}))
        }
      else {
        api.dispatch(openredirect({isAuth:true}))
      }

      api.dispatch(openToast({ message: 'wstąpił błąd \n - '+action?.payload?.data?.title || "", type:'error'}))

    }



    return next(action)
  }