// assets
import { mdiRulerSquareCompass, mdiPlus, mdiSailBoat, mdiAccount, mdiRobotOutline } from '@mdi/js';

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const automat = {
    id: 'automat',
    title: 'Automatyka',
    type: 'group',
    children: [
        {
            id: 'RobotSetup',
            title: 'Zasady',
            type: 'item',
            url: '/robotSetup/rules',
            icon: mdiRobotOutline,
            target: false
        },
        {
            id: 'RobotSetupAction',
            title: 'Akcje',
            type: 'item',
            url: '/robotSetup/action',
            icon: mdiRobotOutline,
            target: false
        },


    ]
};

export default automat;
