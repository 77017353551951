import { BellOutlined } from "@ant-design/icons"
import { Badge } from "@mui/material"
import { useUnSeenQuery } from "../../../../services/unSeeanApi"

const BellNotification = () => {
    const { data, refetch } = useUnSeenQuery(undefined, { pollingInterval: 5000 })

    return (
        <Badge badgeContent={data?.notSeen || 0} color="primary">
        <BellOutlined />
    </Badge>
    )
}

export default BellNotification