// assets
import { mdiMultimedia } from '@mdi/js';
// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const bookingsInne = {
    id: 'bookingInne',
    title: 'Rezerwacje Inne',
    type: 'group',
    children: [
        {
            id: 'bookingListInne',
            title: 'Lista rezerwacji',
            type: 'item',
            url: '/bookingInne/list',
            icon: mdiMultimedia,
            target: false
        },

    ]
};

export default bookingsInne;
