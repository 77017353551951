import { isFulfilled, isRejected } from '@reduxjs/toolkit'
import type { MiddlewareAPI, Middleware} from '@reduxjs/toolkit'
import { openToast } from '../store/toast';
import { openredirect} from '../store/redirect';
import { Cookies } from 'react-cookie';
import { store } from '../store';



export const isAuth: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {

    // console.log(new Cookies().get('token'),'<--token')
    // if (new Cookies().get('token') === undefined && store.getState().redirect.isAuth===true)
    // {
    //   api.dispatch(openredirect({ isAuth: false }))

    // }

      return next(action)

  }