import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import React from 'react';
// material-ui
import { Box, List, Typography } from '@mui/material';
import {activeMenuOpen} from '../../../../../store/menu';
// project import
import NavItem from './NavItem';
import { useDispatch} from 'react-redux';
import { useTheme } from '@mui/material/styles';
// ==============================|| NAVIGATION - LIST GROUP ||============================== //

type NavGroupType = {
    item: any
}

const NavGroup = (props: NavGroupType) => {
    const dispatch = useDispatch();
    const menu = useSelector((state: any) => state.menu);
    const { drawerOpen, currentMenuOpen, openItem} = menu;
    const theme=useTheme();


    const toogle = () => {

        dispatch(activeMenuOpen(props.item.id))
    }



    return (
        <List
            subheader={
                props.item.title &&
                drawerOpen && (
                    <Box sx={{ pl: 3, mb: 1.5 }} onClick={()=>{toogle()}}>
                        <Typography variant="subtitle1" color={theme.palette.navbarText.item}>
                            {props.item.title}
                        </Typography>
                    </Box>
                )
            }
            sx={{ mb: drawerOpen ? 1.5 : 0, py: 0, zIndex: 0 }}
        >
            {
                props.item.children?.map((menuItem: any) => {
                   if(props.item.id=== currentMenuOpen){
                      return (<NavItem key={menuItem.id} item={menuItem} level={1} />);
                    }
                })
           }
        </List>
    );
};


export default NavGroup;
