import React from 'react'
type iconprops = {
    path: any;
    size: number;
    fillColor?: string;
}
const Icon = (props:iconprops) => {
    
    const fillColor2 = props.fillColor ? props.fillColor : 'inherit'
   
    return (<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
        preserveAspectRatio="none" role="presentation" width="16" height="16">
        <path d={props.path} style={{ "fill":fillColor2 }} /></svg>)
}

export default Icon