// project import
import pages from './pages';
import creator from './creator';
import media from './media'
import tournus from './tournus';
import bookings from './bookings'
import document from './documents'
import dicts from './dicts'
import automat from './automat'
import menuCreate from './menu'
import imprezy from './imprezy'
import bookingInne from './bookingsInne'

// ==============================|| MENU ITEMS ||============================== //

type menuItemsType = {
    items:any[]
}

const menuItems:menuItemsType= {
    items: [ pages, menuCreate, creator, media, bookings,bookingInne, tournus,imprezy,document,dicts,automat]
};

export default menuItems;
