// project import
import Routes from './routes/index'
import ThemeCustomization from './themes';
import ScrollTop from './components/ScrollTop';
import './App.css';
import { CookiesProvider } from 'react-cookie';
// ==============================|| APP - THEME, ROUTER, LOCAL  ||============================== //

const App = () => (

    <ThemeCustomization>

        <ScrollTop>
            <Routes />
            </ScrollTop>

    </ThemeCustomization>
);

export default App;
