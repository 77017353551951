// ==============================|| OVERRIDES - INPUT LABEL ||============================== //
import { Theme } from '@mui/material/styles';
export default function Paper(theme: Theme) {
    return {
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: 'none'
                }
            }
        }
    };
}
