import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

// scroll bar
//import './simplebar/src/simplebar.css';

// third-party
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './store';
import Guard from './quard';
// apex-chart
import './assets/third-party/apex-chart.css';

// project import
import App from './App';



// ==============================|| MAIN - REACT DOM RENDER  ||============================== //

const container = document.getElementById('root') as HTMLElement;
const root = createRoot(container); // createRoot(container!) if you use TypeScript
const isAuth = store.getState().redirect.isAuth

// if(!isAuth && window.location.pathname!='/login'){
//     window.location.href='/login'
// }
// {isAuth || (!isAuth && window.location.pathname=='/login') &&(
root.render(
    <StrictMode>
        <ReduxProvider store={store}>

        <Guard>

            <BrowserRouter basename="/">

                    <App />

                    </BrowserRouter>

                    </Guard>

        </ReduxProvider>
    </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

