import { lazy } from 'react';

// project import
import Loadable from '../components/Loadable';
import MainLayout from '../layout/MainLayout';



// render - dashboard
// const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

// render - sample page
// const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// render - utilities
// const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
// const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
// const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
// const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));
// import Media from '../pages/media/media'

const PageGenerator = Loadable(lazy(() => import('../pages/pageGenerator/pageGenerator')));
const PageCreate = Loadable(lazy(() => import('../pages/pageCreator/createPage')));
const PageCreateList = Loadable(lazy(() => import('../pages/pageCreator/pageCreateList')));
const Media = Loadable(lazy(() => import('../pages/media/media')));
const TurnusCreate = Loadable(lazy(() => import('../pages/turnus/turnusCreate')));
const PageGeneratorList = Loadable(lazy(() => import('../pages/pageGenerator/pageGeneratorList')));
const TurnusList = Loadable(lazy(() => import('../pages/turnus/turnusList')));
const BookingList = Loadable(lazy(() => import('../pages/booking/bookingList')));
const BookingCreate = Loadable(lazy(() => import('../pages/booking/booking')));
const Dokument = Loadable(lazy(() => import('../pages/document/document')));
const DokumentsList = Loadable(lazy(() => import('../pages/document/documentsList')));
const DokumentStatic = Loadable(lazy(() => import('../pages/documentStatic/documentStatic')));
const DokumentStaticList = Loadable(lazy(() => import('../pages/documentStatic/documentStaticList')));
const MailTemplate = Loadable(lazy(() => import('../pages/mailTemplate/mailTemplate')));
const MailTemplateList = Loadable(lazy(() => import('../pages/mailTemplate/mailTemplateList')));
const JachtList = Loadable(lazy(() => import('../pages/jacht/jachtList')));
const SkiperList = Loadable(lazy(() => import('../pages/skiper/skiperList')));
const RulesSetup = Loadable(lazy(() => import('../pages/robotSetup/rulesSetup')));
const ActionSetup = Loadable(lazy(() => import('../pages/robotSetup/actionSetup')));
const Dashboard = Loadable(lazy(() => import('../pages/dashboard')));
const Cupons = Loadable(lazy(() => import('../pages/cupons/cupons')));
const Settings = Loadable(lazy(() => import('../pages/settings')));
const Profile = Loadable(lazy(() => import('../pages/profile/profile')));
const ProfileList = Loadable(lazy(() => import('../pages/profile/profileList')));
const ProfileForegin = Loadable(lazy(() => import('../pages/profile/profileForegin')));
const MenuAdd = Loadable(lazy(() => import('../pages/menu/add')));
const MenuList = Loadable(lazy(() => import('../pages/menu/list')));
const StatusList = Loadable(lazy(() => import('../pages/status/statusList')));
const ImprezaList = Loadable(lazy(() => import('../pages/imprezy/imprezaList')));
const ImprezaCreate = Loadable(lazy(() => import('../pages/imprezy/imprezaCreate')));
const BookingInneCreate = Loadable(lazy(() => import('../pages/bookingInne/booking')));
const BookingInneList = Loadable(lazy(() => import('../pages/bookingInne/bookingList')));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <Dashboard />

        },
        {
            path: '/settings',
            element: <Settings />

        },
        {
            path: '/status',
            element: <StatusList />
        },
        {
            path: '/profile',
            children: [
                {
                    path: 'list',
                    element: <ProfileList />
                },

                {
                    path: 'profile',
                    element: <Profile />
                },
                {
                    path: 'foregin',
                    children: [

                        {
                            path: ':id',
                            element: <ProfileForegin />
                        },
                        {
                            path: 'add',
                            element: <ProfileForegin />
                        }
                    ]
                }


            ]
        },
        {
            path: '/menu',
            children: [
                {
                    path: 'list',
                    element: <MenuList />
                },

                {
                    path: 'add',
                    element: <MenuAdd/>
                },
                {
                    path: 'edit/:id',
                    element: <MenuAdd/>
                },


            ]
        },

        {
            path: 'mailTemplate', children: [
                {
                    path: 'list',
                    element: <MailTemplateList />
                },
                {
                    path: 'add',
                    element: <MailTemplate />
                },
                {
                    path: ':id',
                    element: <MailTemplate />
                }
            ]
        },
        {
            path: 'jacht', children: [
                {
                    path: 'list',
                    element: <JachtList />
                },

            ]
        },
        {
            path: 'robotSetup', children: [
                {
                    path: 'rules',
                    element: <RulesSetup />
                },
                {
                    path: 'action',
                    element: <ActionSetup />
                },

            ]
        },
        {
            path: 'skiper', children: [
                {
                    path: 'list',
                    element: <SkiperList />
                },

            ]
        },
        {
            path: 'cupons', children: [
                {
                    path: 'list',
                    element: <Cupons />
                },

            ]
        },
        {
            path: 'document', children: [
                {
                    path: 'list',
                    element: <DokumentsList />
                },
                {
                    path: 'add',
                    element: <Dokument />
                },
                {
                    path: ':id',
                    element: <Dokument />
                }
            ]
        },
        {
            path: 'documentStatic', children: [
                {
                    path: 'list',
                    element: <DokumentStaticList />
                },
                {
                    path: 'add',
                    element: <DokumentStatic />
                },
                {
                    path: ':id',
                    element: <DokumentStatic />
                }
            ]
        },
        {
            path: 'booking',
            children: [{
                path: 'list',
                element: <BookingList />
            },
            {
                path: 'add',
                element: <BookingCreate />
            },
            {
                path: ':id',
                element: <BookingCreate />
            }
            ]
        },
        {
            path: 'bookingInne',
            children: [{
                path: 'list',
                element: <BookingInneList />
            },
            {
                path: 'add',
                element: <BookingInneCreate />
            },
            {
                path: ':id',
                element: <BookingInneCreate />
            }
            ]
        },

        {
            path: 'media',
            element: <Media />
        },

        {
            path: 'turnus',
            children: [
                {
                    path: 'list',
                    element: <TurnusList />
                },
                {
                    path: 'add',
                    element: <TurnusCreate />
                },
                {
                    path: ':id',
                    element: <TurnusCreate />
                }]


        },
        {
            path: 'impreza',
            children: [
                {
                    path: 'list',
                    element: <ImprezaList />
                },
                {
                    path: 'add',
                    element: <ImprezaCreate />
                },
                {
                    path: ':id',
                    element: <ImprezaCreate />
                }]


        },
        {
            path: 'page',
            children: [
                {
                    path: 'add',
                    element: <PageCreate />
                },
                {
                    path: 'list',
                    element: <PageCreateList />
                },
                {
                    path: ':id/',
                    element: <PageCreate />
                }
            ]

        },
        {
            path: 'schema',

            children: [
                {
                    path: 'add',
                    element: <PageGenerator />,
                },
                {
                    path: 'list',
                    element: <PageGeneratorList />
                },
                {
                    path: ':id',
                    element: <PageGenerator />
                },
            ]
        },

    ]
};

export default MainRoutes;
