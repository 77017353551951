import PropTypes from 'prop-types';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Stack, Chip } from '@mui/material';
import { Link } from "react-router-dom";
// project import
import DrawerHeaderStyled from './DrawerHeaderStyled';
import Logo from '../../../../components/Logo/index';
import { version, name } from '../../../../conf/version'

// ==============================|| DRAWER HEADER ||============================== //
type OpenProp = {
    open?: boolean
};

const DrawerHeader = ({ open }:OpenProp) => {
    const theme = useTheme();

    return (
        // only available in paid version
        <DrawerHeaderStyled theme={theme} open={open}>
            <Stack direction="row" spacing={1} alignItems="center">
                {name} <Link to="/"><Logo sx={{ w: '40px', h: '40px' }} /></Link>
                <Chip
                    label={version}
                    size="small"
                    sx={{ height: 16,  color:'white','& .MuiChip-label': { fontSize: '0.625rem', py: 0.25 } }}
                    component="a"
                    target="_blank"
                    clickable
                />
            </Stack>
        </DrawerHeaderStyled>
    );
};

DrawerHeader.propTypes = {
    open: PropTypes.bool
};

export default DrawerHeader;
