// assets

import {  mdiSailBoat,mdiPlus, mdiGuitarAcoustic } from '@mdi/js';




// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const imprezy = {
    id: 'imprezy',
    title: 'Imprezy Inne',
    type: 'group',
    children: [
        {
            id: 'imprezyList',
            title: 'Lista',
            type: 'item',
            url: '/impreza/list',
            icon: mdiGuitarAcoustic,
            target: false
        },
        {
            id: 'imprezyAdd',
            title: 'Dodaj',
            type: 'item',
            url: '/impreza/add',
            icon: mdiPlus,
            target: false
        },

    ]
};

export default imprezy;
