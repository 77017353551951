// material-ui
import { PaletteColorOptions, createTheme } from '@mui/material/styles';

// third-party
//import { presetPalettes } from '@ant-design/colors';

// project import
import ThemeOption from './theme';

// ==============================|| DEFAULT THEME - PALETTE  ||============================== //
declare module '@mui/material/styles' {
    interface Theme {
        customShadows: { [key: string]: any },
        navbar?: { [key: string]: any }
        navbarText?: { [key: string]: any }
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        //customShadows: { [key: string]: any },
        custom?: { [key: string]: any }
        navbar?: { [key: string]: any }
    }
    interface PaletteOptions {
        navbarText:  {[key: string]: any }

      }

}


const Palette = (mode:any[]) => {
    const colors:{[key: string]: any[]} = {
        grey:[]
    }

    const greyPrimary = [
        '#ffffff',
        '#fafafa',
        '#f5f5f5',
        '#f0f0f0',
        '#d9d9d9',
        '#bfbfbf',
        '#8c8c8c',
        '#595959',
        '#262626',
        '#141414',
        '#000000'
    ];
    const greyAscent = ['#fafafa', '#bfbfbf', '#434343', '#1f1f1f'];
    const greyConstant = ['#fafafb', '#e6ebf1'];

    colors.grey = [...greyPrimary, ...greyAscent, ...greyConstant];

    const paletteColor = ThemeOption(colors);

    const colorNew = [
        "#00000"
    ]

    return createTheme({
        palette: {
            ...mode,
            common: {
                black: '#000',
                white: '#fff'
            },
            text: {
                primary: greyPrimary[1],
                secondary: greyPrimary[1],
                disabled: greyPrimary[1]
            },
            action: {
                disabled: greyPrimary[1]
            },
            divider: greyPrimary[1],
            navbarText: { item: '#c5c5c5' },


            background: {
                paper: greyPrimary[1],
                default: greyPrimary[1]
            }
        }
    });
};

export default Palette;
