// material-ui
// @ts-nocheck
import { styled, Theme } from '@mui/material/styles';
import Drawer from '@mui/material/Drawer';

// project import
import { drawerWidth } from '../../../config';

const openedMixin = (theme: Theme) => ({
   width: drawerWidth,
   borderRight: `1px solid ${theme.palette.divider}`,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
    }),
    overflowX: 'hidden',
    boxShadow: 'none',
    backgroundColor: theme.palette.navbar,
    color: 'white',

});

const closedMixin = (theme: Theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
    }),
    overflowX: 'hidden',
    width: 0,
    borderRight: 'none',
    boxShadow: theme.customShadows.z1,
    backgroundColor: theme.palette.navbar,
    color:'white'
});

// ==============================|| DRAWER - MINI STYLED ||============================== //
type OpenProp = {
    open?: boolean;
    drawerWidth?: string;
};
// @ts-nocheck
const MiniDrawerStyled = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })<OpenProp>(({ theme, open }) => ({
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open==true && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme)
    }),
    ...(open!=true && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme)
    })
}));



export default MiniDrawerStyled;
