// assets

import { mdiEmail, mdiFileDocument } from "@mdi/js";

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const document = {
    id: 'dokument',
    title: 'Papiery',
    type: 'collapse',
    children: [
        {
            id: 'dokumentDyn',
            title: 'Dokumenty',
            type: 'item',
            url: '/document/list',
            icon: mdiFileDocument,
            target: false
        },
        {
            id: 'dokumentStat',
            title: 'Dokumenty statyczne',
            type: 'item',
            url: '/documentStatic/list',
            icon: mdiFileDocument,
            target: false
        },
        {
            id: 'mailTemplate',
            title: 'Mail Template',
            type: 'item',
            url: '/mailTemplate/list',
            icon: mdiEmail,
            target: false
        },


    ]
};

export default document;
