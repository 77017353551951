// assets
import { mdiMultimedia } from '@mdi/js';
// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const bookings = {
    id: 'booking',
    title: 'Rezerwacje',
    type: 'group',
    children: [
        {
            id: 'bookingList',
            title: 'Lista rezerwacji',
            type: 'item',
            url: '/booking/list',
            icon: mdiMultimedia,
            target: false
        },

    ]
};

export default bookings;
