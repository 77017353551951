import PropTypes from 'prop-types';

// material-ui
import { alpha, styled } from '@mui/material/styles';
import { Box, SxProps } from '@mui/material';

// third-party
import SimpleBar from 'simplebar-react';
import { BrowserView, MobileView } from 'react-device-detect';
import { ReactNode } from 'react';


// root style
const RootStyle = styled(BrowserView)({
    flexGrow: 1,
    height: '100%',
    overflow: 'auto'
});

// scroll bar wrapper
const SimpleBarStyle = styled(SimpleBar)(({ theme }) => ({
    maxHeight: '100%',
    '& .simplebar-scrollbar': {
        '&:before': {
            backgroundColor: 'transparent'
        },
        '&.simplebar-visible:before': {
            opacity: 1
        }
    },
    '& .simplebar-track.simplebar-vertical': {
        width: 1
    },
    '& .simplebar-track.simplebar-horizontal .simplebar-scrollbar': {
        height: 1
    },
    '& .simplebar-mask': {
        zIndex: 'inherit'
    }
}));


type SimpleBarScrollType = {
    children: ReactNode;
    sx: SxProps;
}


export default function SimpleBarScroll({ children, sx, ...other }:SimpleBarScrollType) {
    return (
        <>
            <RootStyle>
                <SimpleBarStyle timeout={300} clickOnTrack={false} sx={sx} {...other}>

                        {children}

                </SimpleBarStyle>
            </RootStyle>
            <MobileView>
                {/* <Box sx={{ overflowX: 'auto', ...sx }} {...other}> */}
                <SimpleBarStyle timeout={500} clickOnTrack={true} sx={sx} {...other}>
                    {children}
                    </SimpleBarStyle>
                {/* </Box> */}
            </MobileView>
        </>
    );
}

SimpleBarScroll.propTypes = {
    children: PropTypes.node,
    sx: PropTypes.object
};
