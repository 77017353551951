// assets
import { mdiRulerSquareCompass, mdiPlus, mdiSailBoat, mdiAccount, mdiPercentBox, mdiMenuDownOutline, mdiMenu } from '@mdi/js';

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const menuCreate = {
    id: 'menuPos',
    title: 'Menu',
    type: 'group',
    children: [
        {
            id: 'menuList',
            title: 'Lista menu',
            type: 'item',
            url: '/menu/list',
            icon: mdiMenu,
            target: false
        },
        {
            id: 'menuAdd',
            title: 'Dodaj',
            type: 'item',
            url: '/menu/add',
            icon: mdiPlus,
            target: false
        },

    ]


};

export default menuCreate;
