import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { serviceUrl } from '../config'
import { RootState } from '../store'
import {Cookies} from 'react-cookie'

export const mediaApi = createApi({
    reducerPath: 'mediaApi',
    baseQuery: fetchBaseQuery({
        baseUrl: serviceUrl,
        prepareHeaders: (headers) => {
       const token = new Cookies().get('token')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    }
    }),
    tagTypes: ['media'],
    endpoints: (builder) => ({
        media: builder.query({
            query: (arg) => {

                    return { url: `/media/?dir=${arg}`, method: 'GET' }

            },

        }),

        updateMedia: builder.mutation({
            query: ({ id, ...rest }) => ({
                url: `/media/${id}`,
                method: 'PUT',
                body: rest
            }),
            invalidatesTags: ['media']
        }),
        deleteMedia: builder.mutation({
            query: (imgs) => ({
                url: `/media/`,
                method: 'DELETE',
                body:imgs
            }),
            invalidatesTags: ['media']
        })
    })
});

export const { useMediaQuery, useUpdateMediaMutation, useDeleteMediaMutation } = mediaApi;
