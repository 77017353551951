import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';


import Icon from '@mdi/react';
import React, { FC } from 'react'
import { mdiAccountEditOutline, mdiAccountMultipleCheckOutline, mdiAccountPlusOutline, mdiCog, mdiLogout, mdiLogoutVariant, mdiPencilCircleOutline } from '@mdi/js';
import { Cookies } from 'react-cookie';
// ==============================|| HEADER PROFILE - PROFILE TAB ||============================== //

type ProfileTabProsp = {
    handleLogout:()=>void
}
const ProfileTab = (props:ProfileTabProsp) => {
    const theme = useTheme();

    const [selectedIndex, setSelectedIndex] = useState(0);
    const handleListItemClick = (event: React.SyntheticEvent, index:number) => {
        setSelectedIndex(index);
        if (index === 1) {
            window.location.href = '/settings'
        }
        if (index === 0) {
            window.location.href = '/profile/profile'
        }
        if (index === 2) {
            window.location.href = '/profile/foregin/add'
        }
        if (index === 3) {
            window.location.href = '/profile/list'
        }
    };
    const role=new Cookies().get('role')

    return (
        <List component="nav" sx={{ p: 0, '& .MuiListItemIcon-root': { minWidth: 32, color: theme.palette.grey[500] } }}>
            <ListItemButton selected={selectedIndex === 0} onClick={(event: React.SyntheticEvent) => handleListItemClick(event, 0)}>
                <ListItemIcon>
                <Icon path={mdiAccountEditOutline} size={0.8} />
                </ListItemIcon>
                <ListItemText primary="Edytuj profil" />
            </ListItemButton>
            {['SU', 'SA'].includes(role) && (
                <>
            <ListItemButton selected={selectedIndex === 2} onClick={(event: React.SyntheticEvent) => handleListItemClick(event, 2)}>
                <ListItemIcon>
                <Icon path={mdiAccountPlusOutline} size={0.8} />
                </ListItemIcon>
                <ListItemText primary="Dodaj profil" />
            </ListItemButton>
            <ListItemButton selected={selectedIndex === 3} onClick={(event: React.SyntheticEvent) => handleListItemClick(event, 3)}>
                <ListItemIcon>
                <Icon path={mdiAccountMultipleCheckOutline} size={0.8} />
                </ListItemIcon>
                <ListItemText primary="Listuj profil" />
                    </ListItemButton>
                    </>
                )}
            <ListItemButton selected={selectedIndex === 1} onClick={(event: React.SyntheticEvent) => handleListItemClick(event, 1)}>
                <ListItemIcon>
                    <Icon path={mdiCog} size={0.8} />
                </ListItemIcon>
                <ListItemText primary="Konfiguracja" />
            </ListItemButton>


            <ListItemButton selected={selectedIndex === 2} onClick={props.handleLogout}>
                <ListItemIcon>

                    <Icon path={mdiLogout} size={0.8} />
                </ListItemIcon>
                <ListItemText primary="Wyloguj" />
            </ListItemButton>
        </List>
    );
};

ProfileTab.propTypes = {
    handleLogout: PropTypes.func
};

export default ProfileTab;
