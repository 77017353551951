// assets
import { mdiRulerSquareCompass, mdiPlus, mdiSailBoat, mdiAccount, mdiPercentBox, mdiStarBox } from '@mdi/js';

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const pages = {
    id: 'dicts',
    title: 'Słowniki',
    type: 'group',
    children: [
        {
            id: 'jachtList',
            title: 'Jachty',
            type: 'item',
            url: '/jacht/list',
            icon: mdiSailBoat,
            target: false
        },
        {
            id: 'skiperList',
            title: 'Skiperzy',
            type: 'item',
            url: '/skiper/list',
            icon: mdiAccount,
            target: false
        },
        {
            id: 'cuponsList',
            title: 'Kupony',
            type: 'item',
            url: '/cupons/list',
            icon: mdiPercentBox,
            target: false
        },
        {
            id: 'statusList',
            title: 'Statusy',
            type: 'item',
            url: '/status',
            icon: mdiStarBox,
            target: false
        },

    ]
};

export default pages;
