// assets
import { mdiRulerSquareCompass, mdiPlus } from '@mdi/js';

// ==============================|| MENU ITEMS - EXTRA PAGES ||============================== //

const pages = {
    id: 'schema',
    title: 'Schematy',
    type: 'group',
    children: [
        {
            id: 'schemaList',
            title: 'Lista schematów',
            type: 'item',
            url: '/schema/list',
            icon: mdiRulerSquareCompass,
            target: false
        },
        {
            id: 'schemaAdd',
            title: 'Dodaj schemat',
            type: 'item',
            url: '/schema/add',
            icon: mdiPlus,
            target: false
        }

    ]
};

export default pages;
