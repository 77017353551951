import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { useTurnusQuery } from "../../services/turnusApi";
import { useAddFastBookingMutation } from "../../services/bookingApi";

export const BookingDialog = (props: {
  open: boolean;
  close: () => void;

}) => {

  const [impreza, setImpreza] = React.useState<any>(undefined);
  const [ mutate, result ] = useAddFastBookingMutation();

  const { data: turnus } = useTurnusQuery(undefined);
  const { data: turnusItem } = useTurnusQuery(impreza);

  const FormInital = {
    name: "",
    surname: "",
    email: "",
    phone: "",
    turnus: "",
    impreza: "",
    ignorePlaces: false,
    persons: 1,
    turnusx: {
      main: "",
      id:""
    },
    finanse: [{
      price:0
    }]
  }

  //reducer for formInitial
  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case "name":
        return { ...state, name: action.payload };
      case "surname":
        return { ...state, surname: action.payload };
      case "email":
        return { ...state, email: action.payload };
      case "phone":
        return { ...state, phone: action.payload };
      case "turnus":

        const turnusItemx = turnusItem[0]?.blocks?.turnus?.find((item: any) => item.id === action.payload)
        const price = turnusItemx?.price
        return { ...state, turnus: action.payload, turnusx:{...state.turnusx,id:action.payload},finanse:[{price:price}] };
      case "impreza":


        return { ...state, impreza: action.payload, turnusx:{...state.turnusx,main:action.payload}  };
      case "ignorePlaces":
        return { ...state, ignorePlaces: action.payload };
      case "persons":
        return { ...state, persons: action.payload };
      default:
        return state;
    }
  };

  const [form, dispatch] = React.useReducer(
    reducer,
    FormInital
  );




  const handleChangeImpreza = (event: any) => {
    setImpreza(event.target.value);
    dispatch({ type: 'impreza', payload: event.target.value })
  };


  const handleChangeTurnus = (event: any) => {

    dispatch({ type: 'turnus', payload: event.target.value })
  };


  React.useEffect(() => {
  
    if (result?.data?.status == 'ok') {
      props.close();
  }
  }, [result])


  return (
    <Dialog
      open={props.open}
      onClose={() => {
        props.close();
      }}
      sx={{minWidth:'600px'}}
    >
      <DialogTitle variant="h4">
        Dodaj rezerwację
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: "2em", minWidth: "400px", display:'flex',gap:'1em' }}>

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Wybierz imprezę</InputLabel>
   <Select


            sx={{ width: '100%', '.MuiInputBase-input': {padding: '1.2em'},}}
    //         value={val}


    onChange={handleChangeImpreza}
        >
            {
                 turnus?.map((item: any,index:number) => { return <MenuItem key={index.toString()} value={item._id.$oid}>{item.name}</MenuItem> })
            }
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Wybierz imprezę</InputLabel>
   <Select


            sx={{ width: '100%', '.MuiInputBase-input': {padding: '1.2em'},}}
    //         value={val}


    onChange={handleChangeTurnus}
        >
            {
                 turnusItem && turnusItem[0].blocks?.turnus?.map((item: any,index:number) => { return <MenuItem key={index.toString()} value={item.id}><strong>{item.code}</strong>  / {item.date_from ? item.date_from.substring(0,10) : ''}</MenuItem> })
            }
            </Select>
            </FormControl>

        </Box>

        <Box sx={{mt:'2em'}}>
          <Typography variant="h6">Dane rezerwacji</Typography>
          <Box sx={{ mt: "2em", minWidth: "200px",alignItems:'center' }}>
            <TextField label="Imię osoby zgłaszającej" sx={{ minWidth: '400px', width: '100%' }} value={form.name} onChange={(event) => dispatch({ type: 'name', payload: event.target.value })} />
            <TextField label="Nazwisko osoby zgłaszającej" sx={{ minWidth: '400px', width: '100%', mt: '1.5em' }} value={form.surname} onChange={(event) => dispatch({ type: 'surname', payload: event.target.value })} />
            <TextField label="Email" sx={{ minWidth: '400px', width: '100%', mt: '1.5em' }} value={form.email} onChange={(event) => dispatch({ type: 'email', payload: event.target.value })} />
            <TextField label="Telefon" sx={{ minWidth: '400px', width: '100%', mt: '1.5em' }} value={form.phone} onChange={(event) => dispatch({ type: 'phone', payload: event.target.value })} />
            <Box sx={{ display: 'flex', gap: '1em', mt: '1.5em'  }}>
              <TextField label="Ilość osob" sx={{ minWidth: '100px', }} onChange={(event) => dispatch({ type: 'persons', payload: event.target.value })} />
              <FormControlLabel control={<Checkbox checked={form.ignorePlaces}
                onChange={(event) => {dispatch({type:'ignorePlaces',payload:event.target.checked})  }} />} label="Ignoruj dostępność miejsc" />

              </Box>
            </Box>
        </Box>
      </DialogContent>

      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            props.close();
          }}
        >
          Zamknij
        </Button>
        <Button
          variant="contained"
          onClick={() => {

            mutate(form)
          }}
        >
          Zapisz
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default BookingDialog;
