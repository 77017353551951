
import { differenceInDays, differenceInMinutes, parseISO } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
const minutesPassedFromDate = (dateString: string) => {
    const timeZone = 'Europe/Warsaw';
const currentDate = new Date();
const parsedDate = parseISO(dateString);
const zonedDate = utcToZonedTime(parsedDate, timeZone);
const daysPassed = differenceInDays(currentDate, zonedDate);
    const minutesPassed = differenceInMinutes(currentDate, zonedDate) % 60;
    if (daysPassed > 0) {
        return `${daysPassed} dni i ${minutesPassed} minut temu`;
    }
    else {
        return `${minutesPassed} minut temu`;
    }

}

export default minutesPassedFromDate;
