
// ==============================|| PRESET THEME - THEME SELECTOR ||============================== //






// declare module '@mui/material/styles' {


//     // interface Theme {
//     //     [key:string]:any[] | string |
//     // }
//     // // allow configuration using `createTheme`
//     // interface ThemeOptions {
//     //     [key:string]:any[] | string
//     // }

//     // extend interfaces to add custom 'white' color


// }
declare module '@mui/material/styles' {
    interface Theme {
        customShadows: { [key: string]: any },
        navbar?: { [key: string]: any }
        navbarText?: { [key: string]: any }
    }
    // allow configuration using `createTheme`
    interface ThemeOptions {
        //customShadows: { [key: string]: any },
        custom?: { [key: string]: any }
        navbar?: { [key: string]: any }
    }

    interface PaletteOptions {
        navbarText:  {[key: string]: any }

      }
}
type colorProp={[key:string]:string|string[]}

const Theme = (colors:colorProp) => {
    const { blue,   grey } = colors;
    const greyColors:colorProp = {
        0: grey[0],
        50: grey[1],
        100: grey[2],
        200: grey[3],
        300: grey[4],
        400: grey[5],
        500: grey[6],
        600: grey[7],
        700: grey[8],
        800: grey[9],
        900: grey[10],
        A50: grey[15],
        A100: grey[11],
        A200: grey[12],
        A400: grey[13],
        A700: grey[14],
        A800: grey[16]
    };
    const contrastText = '#fff';

    return {

        primary: {
            lighter: greyColors[0],
            100: greyColors[1],
            200: greyColors[2],
            light: greyColors[3],
            400: greyColors[4],
            main: greyColors[5],
            dark: greyColors[6],
            700: greyColors[7],
            darker: greyColors[8],
            900: greyColors[9],
            contrastText
        },
        secondary: {
            lighter: greyColors[100],
            100: greyColors[100],
            200: greyColors[200],
            light: greyColors[300],
            400: greyColors[400],
            main: greyColors[500],
            600: greyColors[600],
            dark: greyColors[700],
            800: greyColors[800],
            darker: greyColors[900],
            A100: greyColors[0],
            A200: greyColors.A400,
            A300: greyColors.A700,
            contrastText: greyColors[0]
        },
        error: {
            lighter:  greyColors[0],
            light:  greyColors[2],
            main:  greyColors[4],
            dark:  greyColors[7],
            darker:  greyColors[9],
            contrastText
        },
        warning: {
            lighter:  greyColors[0],
            light:  greyColors[3],
            main:  greyColors[5],
            dark:  greyColors[7],
            darker:  greyColors[9],
            contrastText: greyColors[100]
        },
        info: {
            lighter:  greyColors[0],
            light:  greyColors[3],
            main:  greyColors[5],
            dark:  greyColors[7],
            darker:  greyColors[9],
            contrastText
        },
        success: {
            lighter:  greyColors[0],
            light:  greyColors[3],
            main:  greyColors[5],
            dark:  greyColors[7],
            darker:  greyColors[9],
            contrastText
        },
        grey: greyColors
    };
};



export default Theme;
